.App {
  text-align: center;
}

.App-header {
  background-image: url('./map.png');
  background-size: contain;  
  background-color: #0F212E;
  background-repeat:   no-repeat;
  background-position: center center; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FAFAFA;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
